import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';
import { Plugins } from '@capacitor/core';
import { MapService } from 'src/app/services/map.service';
import { StatsService } from 'src/app/services/stats.service';
const { Storage } = Plugins;

@Component({
  selector: 'app-menu-desktop',
  templateUrl: './menu-desktop.component.html',
  styleUrls: ['./menu-desktop.component.scss'],
})
export class MenuDesktopComponent implements OnInit {

  @Input() isMap: boolean;

  inputSelected: boolean = false;
  search: string;

  menuItems: any = [];

  deviceID: string;

  menuSidebarOpen: boolean = false;

  allMarkers: any = [];
  allCategories: any = [];
  catsFiltered: any = [];
  pointsPreview: any = [];

  constructor(private statsService: StatsService, private mapService: MapService, private apiService: ApiService, private router: Router, private translateService: TranslateService) { }

  async getDeviceId() {
    const deviceKey = await Storage.get({ key: 'stats_devices_id' });
    const device = JSON.parse(deviceKey.value);
    this.deviceID = String(device.stats_devices_id);
  }

  async ngOnInit() {
    await this.getDeviceId();
    this.getMarkers();
    setTimeout(() => { 
      this.getMenuItems();
    }, 1000);
    // this.mapService.getPoint().subscribe(
    //   data => { this.closeMenuSidebar(); }
    // );
    this.router.events.subscribe((val) => {
      this.closeMenuSidebar();
    });
  }

  getMarkers() {
    this.allMarkers = [];
    this.apiService.getAllPointsAndVariations(this.deviceID).subscribe(
      data => {
        let pointsFiltered = data.data.points.filter(point => point.publication_count || point.trajet_count);
        let variationsFiltered = data.data.variations.filter(point => point.publication_count || point.trajet_count);
        this.allMarkers = [...pointsFiltered, ...variationsFiltered];
        this.getCategories();
      },
      err => {
        console.log(err);
      }
    );
  }

  getMenuItems() {
    this.apiService.listMenuItem(this.deviceID).subscribe(
      data => {
        let items = data.data;
        items.forEach(element => {
          this.menuItems.push({title: element.name, link: element.url, inapp: element.inapp});
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  onFocus() {
    this.inputSelected = true;
  }

  onBlur() {
    setTimeout( () => this.inputSelected = false, 100 );
  }

  keepFocus(event) {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
    }
    if (event.key === 'ArrowUp') {
      event.preventDefault();
    }
  }

  onSearch(value: string, event) {
    if (value && event.key === 'Enter') {
      this.launchSearch(value);
    }
  }

  launchSearch(string) {
    if (!string) return;
    this.router.navigate(['search'], { queryParams: { s: string } });
  }

  openMenuSidebar() {
    this.menuSidebarOpen = !this.menuSidebarOpen;
  }

  closeMenuSidebar() {
    this.menuSidebarOpen = false;
  }

  getCategories() {
    let lang = this.translateService.getBrowserLang();
    if (lang.startsWith('fr')) lang = 'fr_FR';
    if (lang.startsWith('en')) lang = 'en_GB';
    if (lang.startsWith('de')) lang = 'de_DE';
    if (!lang.startsWith('fr') && !lang.startsWith('en')) lang = 'fr_FR';
    this.apiService.getCategories(lang).subscribe(
      data => {
        let categories = data.data;
        if (categories.length) {
          categories.forEach(cat => {
            let markersCat = this.allMarkers.filter(x => x.catpoint_id === cat.id);
            if (markersCat.length) {
              let id = cat.id;
              let img = cat.medias[0].url;
              let title = cat.name;
              let slug = slugify(cat.name);
              this.allCategories.push({id, img, slug, title}); 
            }
          });
        }
      },
      err => {
        console.log(err);
      }
    );
  } 

  onFilterCat(id) {
    let selected = this.allCategories.find(x => x.id === id);

    if (this.catsFiltered.some(x => x.id === id)) {
      this.catsFiltered = this.catsFiltered.filter(el => el.id != id); 
    } else {
      this.catsFiltered.push(selected);
    }

    if (this.catsFiltered.length) {
      document.querySelectorAll<HTMLElement>('.single-cat .cat').forEach(div => {
        let id = div.id.substring(div.id.lastIndexOf('-') + 1);
        if (this.catsFiltered.some(x => x.id === Number(id))) {
          div.classList.add('active');
          div.classList.remove('unactive');
        } else {
          div.classList.add('unactive');
          div.classList.remove('active');
        }
      });
      if (document.querySelector('.menu-sidebar > .items') as HTMLElement) (document.querySelector('.menu-sidebar > .items') as HTMLElement).style.display = 'none';
    } else {
      document.querySelectorAll<HTMLElement>('.single-cat .cat').forEach(div => {
        div.classList.remove('active');
        div.classList.remove('unactive');
      });
      if (document.querySelector('.menu-sidebar > .items') as HTMLElement) (document.querySelector('.menu-sidebar > .items') as HTMLElement).style.display = 'block';
    }

    this.hideAllMarkers();
    this.showFilteredCats();
  }

  showFilteredCats() {
    let activeMarkers: any = [];
    this.catsFiltered.forEach(cat => {
      let markersFiltered = this.allMarkers.filter(x => x.catpoint_id === cat.id);
      markersFiltered.forEach(marker => {
        activeMarkers.push(marker);
      });
    });

    if (!activeMarkers.length) {
      this.pointsPreview = [];
      this.displayAllMarkers();
      return;
    }

    this.pointsPreview = activeMarkers;
    this.pointsPreview.forEach(element => {
      element.description = element.description.replace(/(<([^>]+)>)/gi, "");
    });
    
    this.mapService.showNewBounds(activeMarkers);    

    this.catsFiltered.forEach(cat => {
      document.querySelectorAll<HTMLElement>('.mapboxgl-marker').forEach(function(marker) {
        if (marker.classList.contains(cat.slug)) {
          marker.style.display = 'block';
        }
      });
    });
  }

  displayAllMarkers() {
    document.querySelectorAll<HTMLElement>('.mapboxgl-marker').forEach(function(marker) {
      marker.style.display = 'block';
    });
    document.querySelectorAll<HTMLElement>('.single-cat .cat').forEach(div => {
      div.classList.remove('active');
      div.classList.remove('unactive');
    });
    this.catsFiltered = [];
    this.mapService.showNewBounds('all');
  }

  hideAllMarkers() {
    document.querySelectorAll<HTMLElement>('.mapboxgl-marker').forEach(function(marker) {
      if (!marker.classList.contains('mapboxgl-user-location-dot')) {
        marker.style.display = 'none';
      }
    });
    this.mapService.showNewBounds('all');
  }

  showPoint(point) {
    this.mapService.showPoint(point);
    if (point.point_id) {
      this.statsService.addStatAction(this.deviceID, 'clic_map', {point_id: point.point_id, variation_id: point.id}).subscribe(
        data => {
        },
        err => {
          console.log(err);
        }
      );
    } else {
      this.statsService.addStatAction(this.deviceID, 'clic_map', {point_id: point.id}).subscribe(
        data => {
        },
        err => {
          console.log(err);
        }
      );
    }
  }

}

function slugify(text) {
  const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
  const to = "aaaaaeeeeeiiiiooooouuuunc------"

  const newText = text.split('').map(
    (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

  return newText
    .toString()                     // Cast to string
    .toLowerCase()                  // Convert the string to lowercase letters
    .trim()                         // Remove whitespace from both sides of a string
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/&/g, '-y-')           // Replace & with 'and'
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-');        // Replace multiple - with single -
}